<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table
    
      ref="table"
      pagination
      :max-items="itemsPerPage"
      search
      :data="reps"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow">
        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                rep.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : rep.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>

      </div>

      <template slot="thead">
        <vs-th sort-key="title">الإسم</vs-th>
        <vs-th sort-key="phone">رقم الهاتف</vs-th>
        <vs-th sort-key="actions">العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="rep" v-for="(rep, index) in data" :key="index">
            <!-- in all these place holders replace them with {{contest.ObjectHere}} -->

            <vs-td>
              <p class="product-name font-medium truncate">
                {{ rep.name }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ rep.phone }}</p>
            </vs-td>
            <vs-td>
              <feather-icon
                icon="RepeatIcon"
                svgClasses="w-5 h-5 hover:text-warning stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(rep.id)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

  </div>
</template>
<script>
import {mapActions} from 'vuex'
 export default {
  data() {
    return {
      selected: [],
      isMounted: false,
      current_Page: 1,
      itemsPerPage: 5,
      totalItems: 0,
      addRep: false,
      editRepBool: false,
      rep_delete: '',

      rep: {
        id: "",
        name: "",
        phone: "",
      },
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.rep.length;
    },
    reps(){
      return this.$store.state.reps.archived
    }
  },
  methods: {
     ...mapActions('reps', [
      'fetchArchivedReps',
      'retrieveRep'
    ]),
    confirmDelete(id)
    {
      this.rep_delete = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد العملية",
        text: "هل انت متأكد من أرشفة هذا المندوب؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },

    deleteData() {
      this.$store.retrieveRep(this.rep_delete)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم أرشفة المندوب بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
  },
  
  mounted() {
    this.fetchArchivedReps()
    this.isMounted = true;
  },
};
</script>